import * as React from "react";
import { useLocation } from "@reach/router";

import { Icon } from "@components/atoms";

import { Container, Item } from "./ShareButtons.styles";
import { useSiteMetadata } from "@hooks";

const buttons = [
  {
    type: "twitter",
    link: (url: string) => `https://twitter.com/intent/tweet?url=${url}&text=`,
  },
  {
    type: "facebook",
    link: (url: string) =>
      `https://www.facebook.com/sharer/sharer.php?u=${url}`,
  },
  {
    type: "linkedin",
    link: (url: string) =>
      `https://www.linkedin.com/shareArticle?mini=true&url=${url}`,
  },
  {
    type: "bmc",
    link: () => `https://www.buymeacoffee.com/codewithbernard`,
  },
];

const ShareButtons: React.FunctionComponent = () => {
  const { pathname } = useLocation();
  const { siteUrl } = useSiteMetadata();

  return (
    <Container>
      {buttons.map(({ type, link }) => (
        <Item key={type}>
          <a
            href={link(`${siteUrl}${pathname}`)}
            target="_blank"
            rel="noreferrer noopener"
          >
            <Icon name={type} size={28} />
          </a>
        </Item>
      ))}
    </Container>
  );
};

export default ShareButtons;
