import { useStaticQuery, graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";

export const useAssets = () => {
  const { author } = useStaticQuery(
    graphql`
      {
        author: file(name: { eq: "author" }) {
          childImageSharp {
            gatsbyImageData(width: 80, quality: 95)
          }
        }
      }
    `
  );

  return {
    author: getImage(author),
  };
};
