import styled from "styled-components";
import { Link, GatsbyLinkProps } from "gatsby";

export const Disclaimer = styled.small`
  font-style: italic;
  margin-top: 16px;
  margin-bottom: -px;
  display: block;
  color: ${(props) => props.theme.pallette.text.secondary};
`;

export const Button = styled(Link)<GatsbyLinkProps<{}>>`
  border: 0;
  border-radius: 10px;
  font-family: ${(props) => props.theme.fontFamily};
  font-size: 16px;
  font-weight: 400;
  overflow: hidden;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  white-space: nowrap;
  color: #fff !important;
  background-color: ${(props) => props.theme.pallette.primary.main};
  transition: background-color 600ms;
  will-change: background-color;
  text-align: center;
  padding: 8px 24px;
  width: fit-content;
  cursor: pointer;
  user-select: none;
`;

export const Article = styled.article`
  img {
    max-width: 100%;
    border-radius: 8px;
  }

  .gatsby-resp-image-wrapper,
  .gatsby-image-wrapper {
    border-radius: ${(props) => props.theme.utils.borderRadius};
    box-shadow: ${(props) => props.theme.utils.boxShadowCard};
    overflow: hidden;
  }

  video {
    width: 100%;
    border-radius: ${(props) => props.theme.utils.borderRadius};
    box-shadow: ${(props) => props.theme.utils.boxShadowCard};
    margin: 12px 0;
    margin-bottom: 24px;
  }

  code[class*="language-"],
  pre[class*="language-"] {
    background-color: ${(props) => props.theme.pallette.background.code};
  }

  .gatsby-highlight {
    margin-bottom: 32px;
  }

  ul,
  ol {
    margin-bottom: 32px;

    li {
      margin-bottom: 16px;
    }
  }

  blockquote {
    margin: 0;
    font-style: italic;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      width: 3px;
      top: -2px;
      bottom: -4px;
      left: -16px;
      background-color: ${(props) => props.theme.pallette.primary.main};
    }
  }

  p,
  li {
    color: ${(props) => props.theme.pallette.text.secondary};
    font-size: 18px;

    & > strong,
    span {
      color: ${(props) => props.theme.pallette.text.secondary};
      font-size: 18px;
    }
  }

  h3 {
    font-size: 28px;
    font-weight: 700;
    margin-top: 64px;
    margin-bottom: 16px;
    color: ${(props) => props.theme.pallette.text.heading};
  }

  h4 {
    margin-top: 64px;
    margin-bottom: 12px;
    font-size: 24px;
    font-weight: 700;
    color: ${(props) => props.theme.pallette.text.secondary};
  }

  h1[id],
  h2[id],
  h3[id],
  h4[id],
  h5[id],
  h6[id] {
    a.anchor.before {
      display: none;
    }
  }

  h5[id] {
    margin-top: -24px;
    margin-bottom: 32px;
    font-size: 12px;
    font-weight: 400;
    color: ${(props) => props.theme.pallette.text.secondary};
    text-align: center;

    a {
      color: ${(props) => props.theme.pallette.text.secondary};
      text-decoration: underline;
    }
  }

  h6 {
    margin: 0;
    color: #ffffff;
    background-color: ${(props) => props.theme.pallette.background.code};
    transform: translate(16px, 12px);
    border-top-left-radius: ${(props) => props.theme.utils.borderRadius};
    border-top-right-radius: ${(props) => props.theme.utils.borderRadius};
    font-family: Roboto Mono, monospace;
    font-size: 14px;
    width: fit-content;
    padding: 4px 12px;
  }

  p {
    margin: 0;
    margin-bottom: 32px;
    line-height: 26px;

    @media (min-width: 768px) {
      line-height: 30px;
    }
  }

  a {
    text-decoration: none;
    color: ${(props) => props.theme.pallette.primary.main};
  }
`;

export const Header = styled.header`
  margin-bottom: 40px;

  .gatsby-image-wrapper {
    border-radius: ${(props) => props.theme.utils.borderRadius};
    box-shadow: ${(props) => props.theme.utils.boxShadowCard};
  }

  h1 {
    margin-bottom: 0;
  }

  img {
    border-radius: 8px;
  }
`;

export const Author = styled.div`
  display: flex;
  align-items: center;
  margin: 24px 0;
  margin-top: 8px;

  & > *:not(:last-child) {
    margin-right: 8px;
  }

  span {
    font-size: 14px;
    font-weight: 300;
    color: ${(props) => props.theme.pallette.text.secondary};
  }

  div {
    width: 6px;
    height: 5px;
    border-radius: 50%;
    background-color: ${(props) => props.theme.pallette.primary.main};
  }
`;
