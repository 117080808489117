import * as React from "react";

import { Input, Button, Icon } from "@components/atoms";

import {
  Container,
  Content,
  Title,
  Description,
  Form,
  AuthorContainer,
  Author,
  Message,
  Close,
} from "./NewsletterModal.styles";
import { useAssets } from "./NesletterModal.hooks";

const { useState, useEffect } = React;

const localStorageKey = "sf654-saf785a-sqf46-sfa54-sfa654fsa";

const Newsletter: React.FC = () => {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [storageKey, setStorageKey] = useState("");

  const { author } = useAssets();

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setLoading(true);

    try {
      // Call Sendinblue API
      const res = await fetch(
        `/.netlify/functions/email?email=${encodeURIComponent(email)}`
      );
      await res.json();

      localStorage.setItem(localStorageKey, "true");
    } catch (error) {
    } finally {
      setLoading(false);
      sessionStorage.setItem(storageKey, "true");
      setShow(false);
    }
  };

  const mouseOut = (event: MouseEvent) => {
    if (event.relatedTarget == null) {
      if (
        !localStorage.getItem(storageKey) &&
        !sessionStorage.getItem(storageKey)
      ) {
        setShow(true);
      }
    }
  };

  useEffect(() => {
    document.documentElement.addEventListener("mouseout", mouseOut);

    return () => {
      document.documentElement.removeEventListener("mouseout", mouseOut);
    };
  }, []);

  if (!show) return null;

  return (
    <Container>
      <Close
        onClick={() => {
          setShow(false);
          sessionStorage.setItem(storageKey, "true");
        }}
      >
        <Icon size={24} name="close" />
      </Close>
      <Content>
        <Title>Build Your Own Startup With React</Title>
        <Description>
          Let me send you the list of <b>5 profitable startup ideas</b>. They're
          easy to build and can <strong>earn you thousand of dollars</strong>{" "}
          each month.
        </Description>
        <Form onSubmit={onSubmit}>
          <Input
            id="email"
            placeholder="Email address"
            onChange={(event) => setEmail(event.target.value)}
            value={email}
          />
          <Button
            disabled={!email.match(/^\S+@\S+\.\S+$/) || loading}
            type="submit"
          >
            Send
          </Button>
        </Form>
        <AuthorContainer>
          <Author image={author} alt="Author" />
          <Message>
            Hi! I'm Bernard, owner and author of this blog. In my free time, I
            like to build micro startups using React and NextJS.
          </Message>
        </AuthorContainer>
      </Content>
    </Container>
  );
};

export default Newsletter;
