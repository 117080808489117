import styled from "styled-components";
import { GatsbyImage, GatsbyImageProps } from "gatsby-plugin-image";

export const Container = styled.div`
  position: fixed;
  z-index: 9999;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: white;
  display: grid;
  place-items: center;
`;

export const Close = styled.span`
  position: fixed;
  top: 32px;
  right: 32px;
  cursor: pointer;
`;

export const Content = styled.div`
  max-width: 600px;
`;

export const Title = styled.h4`
  font-size: 32px;
  text-align: center;
  margin-bottom: 16px;
`;

export const Description = styled.p`
  padding: 0 36px;
  text-align: center;
`;

export const Form = styled.form`
  display: flex;
  max-width: 400px;
  margin: 0 auto;
  align-items: center;
  margin-top: 16px;

  input {
    margin-right: 16px;
  }

  button: {
    flex-shrink: 0;
  }
`;

export const AuthorContainer = styled.div`
  margin-top: 56px;
  display: flex;
  align-items: center;
`;

export const Author = styled(GatsbyImage)<GatsbyImageProps>`
  box-shadow: ${(props) => props.theme.utils.boxShadowCard};
  border-radius: 9999px;
  margin-right: 24px;

  img {
    border-radius: 9999px;
  }
`;

export const Message = styled.span`
  background: #f2f4f6;
  font-size: 14px;
  line-height: 1.71429;
  padding: 10px 16px;
  font-weight: 300;
  border-radius: 4px;
  max-width: 425px;
  text-align: left;
  display: block;
`;
