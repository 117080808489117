import styled from "styled-components";

export const Container = styled.ul`
  position: fixed;
  left: 24px;
  top: 172px;
  margin: 0;
  padding: 0;
  display: none;
  list-style: none;
  flex-direction: column;

  @media (min-width: 1334px) {
    display: flex;
  }
`;

export const Item = styled.li`
  margin: 4px 0;
  transition: transform 0.6s;

  &:hover {
    transform: scale(1.1);
  }
`;
