import * as React from "react";
import { MDXRenderer } from "gatsby-plugin-mdx";
// @ts-ignore
import { MDXProvider } from "@mdx-js/react";
import Google from "@components/Ads/Google";
import ShareButtons from "@components/ShareButtons";
import Widgets from "@components/Widgets";
import { Typography } from "@components/atoms";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { Header, Article, Author, Disclaimer } from "./BlogPost.styles";
import NewsletterModal from "@components/NewsletterModal";

export interface BlogPostProps {
  post: {
    id: string;
    frontmatter: {
      title: string;
      thumbnail: any;
      author: string;
      date: string;
    };
    tableOfContents: any[];
    body: any;
    excerpt: string;
  };
}

const BlogPost: React.FC<BlogPostProps> = ({ post }) => {
  // @ts-ignore
  const tableOfContents = post.tableOfContents.items.map(({ url, title }) => ({
    url: url.substring(1),
    title,
  }));

  // @ts-ignore
  const titles = post.tableOfContents.items.map(({ url, items }) =>
    url.substring(1)
  );

  return (
    <>
      <Widgets postId={post.id} tableOfContents={tableOfContents}>
        <Header>
          <Typography variant="h1">{post.frontmatter.title}</Typography>
          <Author>
            <span>{post.frontmatter.author}</span>
            <div />
            <span>{post.frontmatter.date}</span>
          </Author>
          <GatsbyImage
            style={{ margin: "0 auto" }}
            image={getImage(post.frontmatter.thumbnail)}
            alt={post.frontmatter.title}
          />
          <Disclaimer>
            When you use affiliate links in this article, We earn a small
            comission. Thank you!
          </Disclaimer>
        </Header>
        <Article className="ubc-blog-post-article">
          <MDXProvider>
            <MDXRenderer>{post.body}</MDXRenderer>
          </MDXProvider>
          <Google />
        </Article>
      </Widgets>
      <ShareButtons />
      <NewsletterModal />
    </>
  );
};

export default BlogPost;
