import * as React from "react";
import { graphql } from "gatsby";
import { getSrc } from "gatsby-plugin-image";

import BlogPost from "@components/BlogPost";
import Seo from "@components/Seo";

const BlogPostTemplate = ({ data }) => {
  const post = data.mdx;
  const image = `${data.site.siteMetadata.siteUrl}${getSrc(
    post.frontmatter.thumbnail
  )}`;

  return (
    <>
      <Seo
        canonical={`${data.site.siteMetadata.siteUrl}${post.fields.slug}`}
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
        contentType="article"
        image={image}
      />
      <BlogPost post={post} />
    </>
  );
};

export const pageQuery = graphql`
  query BlogPostBySlug($id: String!) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    mdx(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      body
      fields {
        slug
      }
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        author
        thumbnail {
          childImageSharp {
            gatsbyImageData(width: 1024, quality: 90)
          }
        }
      }
      tableOfContents(maxDepth: 4)
    }
  }
`;

export default BlogPostTemplate;
